import logo from "./logo.svg";

import "./App.css";

import ReactDOM from "react-dom/client";

function App() {
  return <div className="App"></div>;
}

export default App;
