import react from "react";
import { Link } from "react-router-dom";

const Homes = () => {
  return (
    <div class="mt-32">
      <div class="px-4 sm:px-8 max-w-5xl m-auto">
        <h1 class="text-center font-semibold text-sm">Houses</h1>

        <ul class="text-center border border-gray-200 rounded overflow-hidden shadow-md">
          <li class="px-4 py-2 bg-white hover:bg-sky-100 hover:text-sky-900 border-b last:border-none border-gray-200 transition-all duration-300 ease-in-out">
            <Link to={`ampm`}>ampm</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Homes;
