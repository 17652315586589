import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Todo from "./components/todo";
import Homes from "./components/homes";
import archdale from "./data/archdale.js";
import leaveslane from "./data/leaveslane";
import yorkstreet from "./data/yorkstreet";
import container from "./data/container";
import ampm from "./data/ampm";
import myrtleave from "./data/myrtleave";
import pleasant from "./data/pleasant";
import pointlookout from "./data/pointlookout";
import shenandoah from "./data/shenandoah";
import thomasleft from "./data/thomasleft";
import thomasright from "./data/thomasright";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homes />,
  },
  {
    path: "/archdale",
    element: <Todo house={archdale} />,
  },
  {
    path: "/leaveslane",
    element: <Todo house={leaveslane} />,
  },
  {
    path: "/yorkstreet",
    element: <Todo house={yorkstreet} />,
  },
  {
    path: "/container",
    element: <Todo house={container} />,
  },
  {
    path: "/ampm",
    element: <Todo house={ampm} />,
  },
  {
    path: "/myrtleave",
    element: <Todo house={myrtleave} />,
  },
  {
    path: "/pleasant",
    element: <Todo house={pleasant} />,
  },
  {
    path: "/pointlookout",
    element: <Todo house={pointlookout} />,
  },
  {
    path: "/shenandoah",
    element: <Todo house={shenandoah} />,
  },
  {
    path: "/thomasright",
    element: <Todo house={thomasright} />,
  },
  {
    path: "/thomasleft",
    element: <Todo house={thomasleft} />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
