const archdale = {
  house: "Archdale",
  categories: [
    {
      category: "Bathrooms",
      tasks: [
        { task: "Change and replace towels", done: false },
        { task: "Check sink and shower drains for hair or clogs", done: false },
        { task: "Clean faucets", done: false },
        { task: "Clean shower", done: false },
        { task: "Make sure hand soap is available", done: false },
        { task: "Mop floor", done: false },
        { task: "Wash bath mats if they look dirty", done: false },
        { task: "Remove trash", done: false },
        { task: "Spray and rinse shower and shower curtain", done: false },
        { task: "Sweep or vacuum", done: false },
        { task: "Make sure every bathroom has a box of tissues", done: false },
        { task: "A final scan of the room", done: false },
        {
          task: "Make sure 3 rolls of toilet paper in every bathroom",
          done: false,
        },
        {
          task: "Make sure body wash, shampoo and conditioner is left in every shower/ tub and isn't empty",
          done: false,
        },
        { task: "No old soap bars left in showers", done: false },
        { task: "Wipe down the mirrors until they are sparkling", done: false },
      ],
    },

    {
      category: "Bedrooms",
      tasks: [
        { task: "Change and replace sheets and linens", done: false },
        { task: "Inspect closets", done: false },
        {
          task: "Remove any trash from space and empty trash bins",
          done: false,
        },
        { task: "Sweep/vacuum space (including under the bed)", done: false },
        { task: "Wipe down blades of any fans", done: false },
        { task: "Wipe down windows", done: false },
        {
          task: "Two clean towel sets on each bed with body towel, face towel and hand towel",
          done: false,
        },
        { task: "Check towels for stains", done: false },
        {
          task: "Check to make sure no stains on carpets, if stains use resolve to remove",
          done: false,
        },
        { task: "A final scan of the room", done: false },
      ],
    },

    {
      category: "Exterior of property",
      tasks: [
        { task: "Clean BBQ grill or other outdoor cooking", done: false },
        { task: "Remove any trash or debris", done: false },
        { task: "Disinfect any dining areas", done: false },
        { task: "Shake out “Welcome” mats", done: false },
        { task: "Sweep sitting/eating area", done: false },
        { task: "Wipe down patio furniture", done: false },
        {
          task: "Make sure grill is cleaned and we have charcoal or propane ",
          done: false,
        },
        { task: "A final scan of the area", done: false },
      ],
    },

    {
      category: "Kitchen",
      tasks: [
        {
          task: "Check the refrigerator for leftover food or messes",
          done: false,
        },
        { task: "Clean out crumbs or other messes from cabinets", done: false },
        { task: "Clean faucets", done: false },
        { task: "Clean and replace kitchen towels", done: false },
        { task: "Clean sink", done: false },
        {
          task: "Make sure we have dishwasher detergent, sponges, a broom, dust pan and a mop in an unlocked area for guests during their stay.",
          done: false,
        },
        { task: "Empty/run dishwasher as needed", done: false },
        {
          task: "Check plates, silverware and glasses to make sure they are dried and clean",
          done: false,
        },
        {
          task: "Make sure we have at least 5 trash bags available to guests",
          done: false,
        },
        {
          task: "Make sure we have at least two paper towel rolls available ",
          done: false,
        },
        { task: "Make sure hand soap is full", done: false },
        { task: "Remove trash", done: false },
        { task: "Wipe down counters and refrigerator shelves", done: false },
        {
          task: "Check all appliances to make sure they are clean",
          done: false,
        },
        {
          task: "Open microwave and check plate and clean if dirty",
          done: false,
        },
        { task: "A final scan of the room", done: false },
      ],
    },

    {
      category: "Laundry Room/Utility Room",
      tasks: [
        { task: "Check and clean up any detergent spills", done: false },
        {
          task: "Check that laundry baskets are clean and ready for use",
          done: false,
        },
        { task: "Remove dryer lint", done: false },
        {
          task: "Make sure no laundry has been left in the washing machines",
          done: false,
        },
        {
          task: "Wipe down exterior of washer and dryer (be sure to get those buttons and knobs)",
          done: false,
        },
        { task: "A final scan of the room", done: false },
      ],
    },

    {
      category: "Living Room / Family Room",
      tasks: [
        {
          task: "Dust/wipe any furniture and high-touch surfaces",
          done: false,
        },
        { task: "Inspect closets", done: false },
        { task: "Check remote control batteries are working", done: false },
        {
          task: "Remove any trash from space and empty trash bins",
          done: false,
        },
        {
          task: "Sweep/vacuum space (including under couches and tables)",
          done: false,
        },
        { task: "Wipe down blades of any fans", done: false },
        { task: "Wipe down windows", done: false },
        { task: "Check all light bulbs", done: false },
        { task: "Pull out sofa has fresh sheets", done: false },
        {
          task: "Check to make sure no stains on carpets, if stains use resolve to remove",
          done: false,
        },
        { task: "A final scan of the room", done: false },
      ],
    },

    {
      category: "Other / Restock / Message Mike",
      tasks: [
        { task: "Coffee/tea", done: false },
        { task: "Dish soaps/hand soaps", done: false },
        { task: "Clean faucets", done: false },
        { task: "Clean shower", done: false },
        { task: "Make sure hand soap is available", done: false },
        { task: "Mop floor", done: false },
        { task: "Remove trash", done: false },
        { task: "Spray and rinse shower and shower curtain", done: false },
        { task: "Sweep or vacuum", done: false },
        { task: "A final scan of the room", done: false },
      ],
    },
  ],
};

export default archdale;
