import react from "react"; // Make sure React is imported
import { Link } from "react-router-dom";

const Todo = (house) => {
  const [categories, setTodos] = react.useState(house.house.categories);
  const [tasks, setTasks] = react.useState([]);
  categories.forEach((category) => {
    category.tasks.forEach((task) => {
      tasks.push(task);
    });
  });

  console.log(tasks);
  const handleClick = (index, ix) => {
    let newArr = [...categories]; // copying the old datas array

    newArr[index].tasks[ix].done = !newArr[index].tasks[ix].done;

    setTodos(newArr);
  };

  const [language, setLanguage] = react.useState("en"); // Default language is English

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  return (
    <div className="h-100 w-full flex items-center justify-center bg-teal-200 font-sans">
      <div className="bg-white rounded shadow p-6 m-4 w-full lg:w-3/4 lg:max-w-lg">
        <Link className="block" to={`/`}>
          Back
        </Link>
        <h1 className="block text-2xl">{house.house.house}</h1>
        <div>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mr-2"
            onClick={() => handleLanguageChange("en")}
          >
            English
          </button>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 mr-2"
            onClick={() => handleLanguageChange("es")}
          >
            Spanish
          </button>
        </div>

        <div>
          {categories.map((c, index) => {
            return (
              <div className="T$EST">
                <h1 className="text-lg underline">
                  {categories[index].category}
                </h1>
                {categories[index].tasks.map((task, ix) => {
                  return (
                    <div key={task.task} className="flex mb-4 items-center">
                      <p
                        className={`w-full text-grey-darkest ${
                          task.done ? "line-through" : ""
                        }`}
                      >
                        {task.task[language]}
                      </p>
                      <button
                        onClick={() => handleClick(index, ix)}
                        className="flex-no-shrink p-2 ml-4 mr-2 border-2 rounded hover:text-white text-green-500-500 border-green-500 hover:bg-green-500"
                      >
                        Done
                      </button>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div>
          <form
            action={`mailto:m.p.williams006@gmail.com,nicholewithers07@gmail.com?subject=${house.house.house} Walk through`}
            method="post"
            encType="text/plain"
          >
            {categories.map((c, index) => {
              return (
                <div>
                  {categories[index].tasks.map((task, ix) => {
                    return (
                      <input
                        key={task.task}
                        type="hidden"
                        name={`${c.category} - ${task.task.en}`}
                        value={task.done}
                      />
                    );
                  })}
                </div>
              );
            })}

            <input type="hidden" name="house" value={house.house.house} />
            <input
              type="hidden"
              name="completed"
              value={
                (tasks.filter((x, i) => {
                  return x.done;
                }).length /
                  tasks.length) *
                100
              }
            />
            <div className="w-full">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
              >
                Your notes
              </label>
              <textarea
                id="message"
                rows="4"
                name="message"
                className="block p-2.5 text-sm text-gray-900 bg-gray-50 w-full rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your message..."
              ></textarea>
            </div>
            <input
              type="submit"
              className="flex-no-shrink mt-12 p-2 ml-4 mr-2 border-2 rounded hover:text-white hover:cursor-pointer text-green-500 border-green-500 hover:bg-green-500"
              name="submit"
              value="Submit"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Todo;
