const ampm = {
  house: "AMPM",
  categories: [
    {
      category: "Bathrooms",
      tasks: [
        {
          task: {
            en: "Change and replace towels",
            es: "Cambiar y reemplazar toallas",
          },
          done: false,
        },
        {
          task: {
            en: "Check sink and shower drains for hair or clogs",
            es: "Revisar desagües de lavabos y duchas en busca de cabello o obstrucciones",
          },
          done: false,
        },
        { task: { en: "Clean faucets", es: "Limpiar grifos" }, done: false },
        { task: { en: "Clean shower", es: "Limpiar la ducha" }, done: false },
        {
          task: {
            en: "Make sure hand soap is available",
            es: "Asegurarse de que haya jabón de manos disponible",
          },
          done: false,
        },
        { task: { en: "Mop floor", es: "Fregar el suelo" }, done: false },
        {
          task: {
            en: "Wash bath mats if they look dirty",
            es: "Lavar las alfombrillas de baño si están sucias",
          },
          done: false,
        },
        { task: { en: "Remove trash", es: "Sacar la basura" }, done: false },
        {
          task: {
            en: "Spray and rinse shower and shower curtain",
            es: "Rociar y enjuagar la ducha y la cortina de la ducha",
          },
          done: false,
        },
        {
          task: { en: "Sweep or vacuum", es: "Barrer o aspirar" },
          done: false,
        },
        {
          task: {
            en: "Make sure every bathroom has a box of tissues",
            es: "Asegurarse de que cada baño tenga una caja de pañuelos",
          },
          done: false,
        },
        {
          task: {
            en: "A final scan of the room",
            es: "Un escaneo final de la habitación",
          },
          done: false,
        },
        {
          task: {
            en: "Make sure 3 rolls of toilet paper in every bathroom",
            es: "Asegurarse de que haya 3 rollos de papel higiénico en cada baño",
          },
          done: false,
        },
        {
          task: {
            en: "Make sure body wash, shampoo and conditioner is left in every shower/ tub and isn't empty",
            es: "Asegurarse de que haya gel de baño, champú y acondicionador en cada ducha/tina y que no estén vacíos",
          },
          done: false,
        },
        {
          task: {
            en: "No old soap bars left in showers",
            es: "No dejar barras de jabón viejas en las duchas",
          },
          done: false,
        },
        {
          task: {
            en: "Wipe down the mirrors until they are sparkling",
            es: "Limpiar los espejos hasta que brillen",
          },
          done: false,
        },
      ],
    },

    {
      category: "Bedrooms",
      tasks: [
        {
          task: {
            en: "Change and replace sheets and linens",
            es: "Cambiar y reemplazar sábanas y ropa de cama",
          },
          done: false,
        },
        {
          task: { en: "Inspect closets", es: "Inspeccionar armarios" },
          done: false,
        },
        {
          task: {
            en: "Remove any trash from space and empty trash bins",
            es: "Eliminar cualquier basura del espacio y vaciar los botes de basura",
          },
          done: false,
        },
        {
          task: {
            en: "Sweep/vacuum space (including under the bed)",
            es: "Barrer/aspirar el espacio (incluido debajo de la cama)",
          },
          done: false,
        },
        {
          task: {
            en: "Wipe down blades of any fans",
            es: "Limpiar las aspas de cualquier ventilador",
          },
          done: false,
        },
        {
          task: { en: "Wipe down windows", es: "Limpiar las ventanas" },
          done: false,
        },
        {
          task: {
            en: "Two clean towel sets on each bed with body towel, face towel and hand towel",
            es: "Dos juegos de toallas limpias en cada cama con toalla de cuerpo, toalla de cara y toalla de mano",
          },
          done: false,
        },
        {
          task: {
            en: "Check towels for stains",
            es: "Revisar las toallas en busca de manchas",
          },
          done: false,
        },
        {
          task: {
            en: "Check to make sure no stains on carpets, if stains use resolve to remove",
            es: "Revisar que no haya manchas en las alfombras, si hay manchas usar producto para quitarlas",
          },
          done: false,
        },
        {
          task: {
            en: "A final scan of the room",
            es: "Un escaneo final de la habitación",
          },
          done: false,
        },
      ],
    },

    {
      category: "Exterior of property",
      tasks: [
        {
          task: {
            en: "Clean BBQ grill or other outdoor cooking",
            es: "Limpiar la parrilla de barbacoa u otras áreas de cocina al aire libre",
          },
          done: false,
        },
        {
          task: {
            en: "Remove any trash or debris",
            es: "Eliminar cualquier basura o escombros",
          },
          done: false,
        },
        {
          task: {
            en: "Disinfect any dining areas",
            es: "Desinfectar las áreas de comedor",
          },
          done: false,
        },
        {
          task: {
            en: "Shake out “Welcome” mats",
            es: "Sacudir las alfombras de “Bienvenida”",
          },
          done: false,
        },
        {
          task: {
            en: "Sweep sitting/eating area",
            es: "Barrer la zona de estar/comer",
          },
          done: false,
        },
        {
          task: {
            en: "Wipe down patio furniture",
            es: "Limpiar los muebles del patio",
          },
          done: false,
        },
        {
          task: {
            en: "Make sure grill is cleaned and we have charcoal or propane",
            es: "Asegurarse de que la parrilla esté limpia y tengamos carbón o propano",
          },
          done: false,
        },
        {
          task: {
            en: "A final scan of the area",
            es: "Un escaneo final de la zona",
          },
          done: false,
        },
        {
          task: {
            en: "Drop robot vacuum in the pool and make sure it has been cleaned out prior and after",
            es: "Poner el robot aspirador en la piscina y asegurarse de que esté limpio antes y después",
          },
          done: false,
        },
        {
          task: {
            en: "Skim the pool",
            es: "Limpiar la superficie de la piscina",
          },
          done: false,
        },
      ],
    },

    {
      category: "Kitchen",
      tasks: [
        {
          task: {
            en: "Check the refrigerator for leftover food or messes",
            es: "Revisar el refrigerador en busca de sobras de comida o desorden",
          },
          done: false,
        },
        {
          task: {
            en: "Clean out crumbs or other messes from cabinets",
            es: "Limpiar migas u otros desordenes de los gabinetes",
          },
          done: false,
        },
        { task: { en: "Clean faucets", es: "Limpiar grifos" }, done: false },
        {
          task: {
            en: "Clean and replace kitchen towels",
            es: "Limpiar y reemplazar las toallas de cocina",
          },
          done: false,
        },
        { task: { en: "Clean sink", es: "Limpiar el fregadero" }, done: false },
        {
          task: {
            en: "Make sure we have dishwasher detergent, sponges, a broom, dust pan and a mop in an unlocked area for guests during their stay.",
            es: "Asegurarse de que tengamos detergente para lavavajillas, esponjas, una escoba, recogedor y un trapeador en un área sin llave para los huéspedes durante su estadía.",
          },
          done: false,
        },
        {
          task: {
            en: "Empty/run dishwasher as needed",
            es: "Vaciar/usar el lavavajillas según sea necesario",
          },
          done: false,
        },
        {
          task: {
            en: "Check plates, silverware and glasses to make sure they are dried and clean",
            es: "Revisar platos, cubiertos y vasos para asegurarse de que estén secos y limpios",
          },
          done: false,
        },
        {
          task: {
            en: "Make sure we have at least 5 trash bags available to guests",
            es: "Asegurarse de que tengamos al menos 5 bolsas de basura disponibles para los huéspedes",
          },
          done: false,
        },
        {
          task: {
            en: "Make sure we have at least two paper towel rolls available",
            es: "Asegurarse de que tengamos al menos dos rollos de toallas de papel disponibles",
          },
          done: false,
        },
        {
          task: {
            en: "Make sure hand soap is full",
            es: "Asegurarse de que el jabón de manos esté lleno",
          },
          done: false,
        },
        { task: { en: "Remove trash", es: "Sacar la basura" }, done: false },
        {
          task: {
            en: "Wipe down counters and refrigerator shelves",
            es: "Limpiar los mostradores y los estantes del refrigerador",
          },
          done: false,
        },
        {
          task: {
            en: "Check all appliances to make sure they are clean",
            es: "Revisar todos los electrodomésticos para asegurarse de que estén limpios",
          },
          done: false,
        },
        {
          task: {
            en: "Open microwave and check plate and clean if dirty",
            es: "Abrir el microondas y revisar el plato y limpiar si está sucio",
          },
          done: false,
        },
        {
          task: {
            en: "A final scan of the room",
            es: "Un escaneo final de la habitación",
          },
          done: false,
        },
      ],
    },

    {
      category: "Laundry Room/Utility Room",
      tasks: [
        {
          task: {
            en: "Check and clean up any detergent spills",
            es: "Revisar y limpiar cualquier derrame de detergente",
          },
          done: false,
        },
        {
          task: {
            en: "Check that laundry baskets are clean and ready for use",
            es: "Asegurarse de que las cestas de ropa estén limpias y listas para usar",
          },
          done: false,
        },
        {
          task: {
            en: "Remove dryer lint",
            es: "Retirar pelusa de la secadora",
          },
          done: false,
        },
        {
          task: {
            en: "Make sure no laundry has been left in the washing machines",
            es: "Asegurarse de que no se haya dejado ropa en las lavadoras",
          },
          done: false,
        },
        {
          task: {
            en: "Wipe down exterior of washer and dryer (be sure to get those buttons and knobs)",
            es: "Limpiar el exterior de la lavadora y secadora (asegúrese de limpiar los botones y perillas)",
          },
          done: false,
        },
        {
          task: {
            en: "A final scan of the room",
            es: "Un escaneo final de la habitación",
          },
          done: false,
        },
      ],
    },

    {
      category: "Living Room / Family Room",
      tasks: [
        {
          task: {
            en: "Dust/wipe any furniture and high-touch surfaces",
            es: "Limpiar cualquier mueble y superficies de alto contacto",
          },
          done: false,
        },
        {
          task: { en: "Inspect closets", es: "Inspeccionar armarios" },
          done: false,
        },
        {
          task: {
            en: "Check remote control batteries are working",
            es: "Verificar que las baterías del control remoto funcionen",
          },
          done: false,
        },
        {
          task: {
            en: "Remove any trash from space and empty trash bins",
            es: "Eliminar cualquier basura del espacio y vaciar los botes de basura",
          },
          done: false,
        },
        {
          task: {
            en: "Sweep/vacuum space (including under couches and tables)",
            es: "Barrer/aspirar el espacio (incluido debajo de los sofás y mesas)",
          },
          done: false,
        },
        {
          task: {
            en: "Wipe down blades of any fans",
            es: "Limpiar las aspas de cualquier ventilador",
          },
          done: false,
        },
        {
          task: { en: "Wipe down windows", es: "Limpiar las ventanas" },
          done: false,
        },
        {
          task: {
            en: "Check all light bulbs",
            es: "Revisar todas las bombillas",
          },
          done: false,
        },
        {
          task: {
            en: "Pull out sofa has fresh sheets",
            es: "El sofá cama tiene sábanas limpias",
          },
          done: false,
        },
        {
          task: {
            en: "Check to make sure no stains on carpets, if stains use resolve to remove",
            es: "Revisar que no haya manchas en las alfombras, si hay manchas usar producto para quitarlas",
          },
          done: false,
        },
        {
          task: {
            en: "A final scan of the room",
            es: "Un escaneo final de la habitación",
          },
          done: false,
        },
      ],
    },

    {
      category: "Other / Restock / Message Mike",
      tasks: [
        { task: { en: "Coffee/tea", es: "Café/té" }, done: false },
        {
          task: {
            en: "Dish soaps/hand soaps",
            es: "Jabón para platos/jabón de manos",
          },
          done: false,
        },
        { task: { en: "Clean faucets", es: "Limpiar grifos" }, done: false },
        { task: { en: "Clean shower", es: "Limpiar la ducha" }, done: false },
        {
          task: {
            en: "Make sure hand soap is available",
            es: "Asegurarse de que haya jabón de manos disponible",
          },
          done: false,
        },
        { task: { en: "Mop floor", es: "Fregar el suelo" }, done: false },
        { task: { en: "Remove trash", es: "Sacar la basura" }, done: false },
        {
          task: {
            en: "Spray and rinse shower and shower curtain",
            es: "Rociar y enjuagar la ducha y la cortina de la ducha",
          },
          done: false,
        },
        {
          task: { en: "Sweep or vacuum", es: "Barrer o aspirar" },
          done: false,
        },
        {
          task: {
            en: "A final scan of the room",
            es: "Un escaneo final de la habitación",
          },
          done: false,
        },
      ],
    },
  ],
};

export default ampm;
